import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './../node_modules/bulma/css/bulma.css';
import '@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css'

import "@/assets/global.css"

import {library} from '@fortawesome/fontawesome-svg-core'
import {faCopy, faCheckCircle, faCircleNotch, faCircle, faAngleDown, faWallet, faExclamationTriangle, faUser, faArrowRight, faArrowDown} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
library.add(faCopy,faCheckCircle, faAngleDown, faWallet, faExclamationTriangle, faCircle, faCircleNotch, faUser, faArrowRight, faArrowDown)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import http from './libs/http'
Vue.prototype.$http = http
import {messageBus} from './libs/messagebus'
Vue.prototype.$messageBus = messageBus

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import i18n from './i18n'

const token = localStorage.getItem('token')
if(token){
  store.dispatch('refresh', token).catch(()=>{})
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
