<template>
  <div id="app">
    <router-view/>
    <Footer></Footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  
  data: function () {
    return {

  }},
  
  async created () {

  },

  beforeDestroy() {
  },

  methods: {
  },
}
</script>

<style>
</style>
