export default {
    msg: {
      search: {
        poolName:"Pool.always.vip",
        // homeNotice: '欢迎大家将地址更改为VIP钱包地址，以防地址不在线无法接收收益!!!\n钱包官网：https://wallet.always.vip',
        homeNotice1: "Let's grin~~~",
        // homeNotice1: '请勿长期在矿池和VIP钱包存放资产，矿池仅对3天内的收益安全负责！',
        homeNotice2: '1、矿池常见问题中一直有注明"勿将资产长期存放矿池"',
        homeNotice3: '2、VIP钱包为非盈利性设施，且启用之初即强调仅可作临时使用',
        homeNotice4: '3、矿池风险法律申明一直强调需遵守当地法规的情况下使用本服务',
        // homeNotice5: '4、矿池仅',
        // homeNotice1: '已经有超过50%的全网算力在VIP了，如果可以请优先考虑其他GRIN矿池。',
        homeNotice: 'VIP矿池开启0手续费挖矿，请尽情享受吧~~',
        inputEmailText:"输入邮箱",
        seeDetail:"查看详情",
        actionTutorial1:"输入邮箱，点击查看详情",
        actionTutorial2:"点击查看详情,进入详情页面！",
        coin:"币种",
        poolHash:"矿池算力",
        netHash:"全网算力",
        dailyIncome:"预估日收益",
        stratumAddress:"挖矿地址",
        fee:"费率",
        minPay:"最小支付金额",

        emailError:'邮箱错误！',
      },
      footer:{
        WalletText:"钱包:",
        WalletName:"VIP 钱包",
        customerServiceEmailText:"客服邮箱：",
        QQGroupText:"QQ群：",
        customerServiceEmail:"grincalf@gmail.com",
        QQGroup:"824825520",
        DiscordText:"Discord：",
        Discord:"邀请链接",
        TgText:"Telegram：",
        Tg:"链接",
        copyRight:"© pool.always.vip"
      },
      tutorial:{
        mine:{
          title:"接入教程",
          step1:"1、挖矿地址填写：例如grin mini填写：stratum+tcp://grin.always.vip:3344",
          step2:"2、用户名为自己邮箱，用户名和矿机名用'.'分开，例如：邮箱为grin@test.com，矿机名：001，则：grin@test.com.001",
          step3:"3、通过邮箱提现，因此只支持邮箱挖矿",
          example:"设置参考",
        },
        withdraw:{
          title:"提现教程",
          step1:"1、使用挖矿邮箱注册VIP钱包",
          step2:"2、绑定接收收益的地址，GRIN仅支持grin1地址，MWC仅支持MWCMQS地址，均支持添加memo",
          step3:"3、memo添加格式为： grin1xxx:123456 或 mwcmqsxxx:123456",
          // notice:"Grin仅支持slate地址， MWC仅支持MWCMQ地址，推荐使用VIP钱包",
          notice:"注意事项",
          notice1: '1、请勿长期在矿池和VIP钱包存放资产，矿池和VIP钱包仅对3天内的资产安全负责！',
          notice2: '2、VIP钱包为非盈利性设施，仅可作临时使用',
          notice3: '3、请在遵守当地法规的情况下使用本服务',
          example:"邮件参考",
        },
        serviceMail: {
          title:"邮箱列表",
          mail1Purpose:"掉线提醒邮件: ",
          mail1:"vip__pool@126.com ~ vip__pool6@126.com",
          mail2Purpose:"客服邮箱: ",
          mail2:"grincalf@gmail.com",
          mail3Purpose:"VIP钱包邮箱: ",
          mail3:"scuxiaoer@.126com",
        },
        law: {
          title:"法律风险提示",
          content:"使用VIP服务之前，请确定您未被任何国家或地区的行政执法机构限制或禁止使用我们的服务，也非古巴、伊朗、朝鲜、叙利亚以及其他受到相关国家政府或国际机构执行制裁的国家或地区居民。请确保您的行为符合所在的国家或地区当地法律法规或政策。",
        },
        qaList:{
          title:"常见问题",
        }
      },
      summary:{
        wait:"数据加载中，请等一会儿，不要心急，不要频繁刷新哟 ~~~",
        email:"邮箱",
        return:"返回",
        accountInfo:"账户信息",
        todayIncome:"24小时收益",
        toPay:"余额",
        paid:"已支付",
        statusToPay:"待支付",
        totalIncome:"总收益",
        autoBill:"支付余额",
        autoBillOn:"已打开",
        autoBillOff:"已关闭",
        rigs:"矿工",
        onRigs:"在线: ",
        offRigs:"离线: ",
        allRigs:"共计: ",
        rigName:"矿工名",
        hash15m:"1小时算力",
        hash12h:"6小时算力",
        hash24h:"12小时算力",
        hashLowAlert:"最近一小时算力偏低，请注意查看矿机！",
        hashTable:"算力图",
        dailyIncome:"支付记录",
        notice:"",
        date:"日期",
        address:"收款地址",
        amount:"数量",
        hashRate:"算力",
        txSlateID:"交易ID",
        kernel:"交易核",
        status:"状态",
        currentAddress:"当前地址：",
        setWithdrewAddress:"设置地址",
        updateWithdrewAddress:"修改地址",
        inputWithdrewAddressHint:"请输入地址：",
        inputEmailCodeHint:"邮箱验证码：",
        sendEmailCodeBtn:"发送验证码",
        plsInputEmailCodeFirst:"请先输入验证码！",
        wrongGrinAddress:"地址错误，需输入grin开头地址！如有MEMO，填写为：grin1xxxx:123456",
        grinAddressMemoHint:"如有MEMO，填写为：grin1xxxx:123456",
        wrongMWCAddress:"地址错误，需输入mwcmqs开头地址！如有MEMO，填写为：mwcmqsxxxx:123456",
        mwcAddressMemoHint:"如有MEMO，填写为：mwcmqsxxxx:123456",
        confirmUpdateAddress:"确认修改",
        plsBindAddress:"请绑定地址。",
        recentPaidRecords:"最近5日无账单记录",
        recentBlocks:"最近区块",
        coin:"币种",
        time:"时间(UTC)",
        height:"高度",
        reward:"收益",
        noRecentReward:"无最近收益",
      },
      errors: {
        wrongAddress:"地址错误",
        wrongEmailCode:"验证码错误",
        ParamsErr               :"参数错误！",
        BadRequest              :"请求错误！",
        DatabaseError           :"已注册，请直接登录！",
        EmailExistedError       :"已注册，请直接登录！",
        UserInfoError            :"用户名或密码错误！",
        AccountNeedActivateError :"请激活账户！",
        SendEmailError           :"邮件发送失败！",
        ActivateAccountError     :"激活账户失败！",
        ActivateAddressError     :"激活地址失败！",
        UnexpectedError          :"未知错误！",
        AddressInvalidError      :"请先激活地址！",
        WithdrawCloseError       :"暂时无法提现！",
        WithdrawTepCloseError    :"点击'同步地址'可将矿池收益直接支付至钱包tor提现地址，如直接支付至gate。请勿将资产存放VIP钱包超过3天！",
        SyncOk                   :"同步地址成功！后续矿池收益将直接支付至您的grin1地址（gate地址）！",
        WithdrawAmountCloseError :"提现金额错误!",
        GAuthCodeError           :"谷歌验证码错误！",
        TokenError               :"身份验证失败！",
        CoinError                :"币种错误！",
        GAuthBindError           :"请先绑定谷歌验证器！",
        EmailError               :"邮箱错误！",
        BindAddressError         :"绑定地址出错！",
        BindGAuthError           :"绑定谷歌验证器出错！",
        GAuthBoundError          :"已经绑定谷歌验证器，请勿重复绑定！",
        WithdrawNotFinishedError :"请等待上一笔提现完成！",
        BalanceNotEnoughError    :"余额不足！",
        TxNotExistsError         :"交易不存在！",
        TxNotMatchedError        :"交易信息不匹配！",
        TryLaterError            :"请稍后再试！",
        AddressInvalid :"地址错误！",
        ComingSoon :"敬请期待~",
      }


    }
}