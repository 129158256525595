<template>
  <section class="section" style="padding-top:0;">
    <div class="container" style="padding: 1rem 2rem 6rem 2rem;">
        <div class="content has-text-left">
          <h2>{{$t("msg.tutorial.qaList.title")}}</h2>
          <p><a target="_blank" href="http://forum.always.vip/share/detail?id=19">{{$t("msg.tutorial.qaList.title")}}</a></p>
          <h2>{{$t("msg.tutorial.mine.title")}}</h2>
          <p>{{$t("msg.tutorial.mine.step1")}}</p>
          <p>{{$t("msg.tutorial.mine.step2")}}</p>
          <p class="has-text-danger">{{$t("msg.tutorial.mine.step3")}}</p>
          <p><a target="_blank" href="http://cdn.always.vip/mdImages/always_mine.jpeg">{{$t("msg.tutorial.mine.example")}}</a></p>
          <h2>{{$t("msg.tutorial.withdraw.title")}}</h2>
          <p>{{$t("msg.tutorial.withdraw.step1")}}
          <p>{{$t("msg.tutorial.withdraw.step2")}}</p>
          <p>{{$t("msg.tutorial.withdraw.step3")}}</p>
          <h2 class="has-text-danger">{{$t("msg.tutorial.withdraw.notice")}}</h2>
          <p>{{$t("msg.tutorial.withdraw.notice1")}}</p>
          <p>{{$t("msg.tutorial.withdraw.notice2")}}</p>
          <p>{{$t("msg.tutorial.withdraw.notice3")}}</p>
<!--          <p><a target="_blank" href="http://cdn.always.vip/mdImages/always_withdraw.jpeg">{{$t("msg.tutorial.withdraw.example")}}</a></p>-->
          <h2>{{$t("msg.tutorial.serviceMail.title")}}</h2>
          <p>{{$t("msg.tutorial.serviceMail.mail2Purpose")}}{{$t("msg.tutorial.serviceMail.mail2")}}</p>
<!--          <p>{{$t("msg.tutorial.serviceMail.mail3Purpose")}}{{$t("msg.tutorial.serviceMail.mail3")}}</p>-->
<!--          <p>{{$t("msg.tutorial.serviceMail.mail1Purpose")}}{{$t("msg.tutorial.serviceMail.mail1")}}</p>-->
          <h2>{{$t("msg.tutorial.law.title")}}</h2>
          <p>{{$t("msg.tutorial.law.content")}}</p>
        </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tutorial',
}
</script>

<style scoped>
</style>
