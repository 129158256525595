import client from './http'


export async function getPoolsInfo() {
    return client.get('/api/pools')
}

export async function getUserStats(email,coin) {
    return client.get('/api/stats/'+email+'?coin='+coin)
}

export async function autoBill(email,coin) {
    return client.get('/api/auto_withdraw/'+email+'?coin='+coin)
}

export async function setAddress(email,coin,address,emailCode) {
    let data = {};
    data["email"] = email;
    data["coin"] = coin;
    data["address"] =address;
    data["emailCode"] =emailCode;
    const formValues = new URLSearchParams();
    formValues.append('token', '201514DQF');
    formValues.append('data', JSON.stringify(data));

    return client.post('/api/address', formValues)
}

export async function sendEmailCode(email,coin) {
    let data = {};
    data["email"] = email;
    data["coin"] = coin;
    const formValues = new URLSearchParams();
    formValues.append('token', '123456');
    formValues.append('data', JSON.stringify(data));

    return client.post('/api/emailCode', formValues)
}