export default {
    msg: {
        search: {
            poolName:"Pool.always.vip",
            // homeNotice: 'You are welcome to change your address to the VIP wallet address in case the address is not online to receive the earnings!!! Wallet official website: https://wallet.always.vip',
            homeNotice1: "Let's grin~~~",
            // homeNotice1: 'Please do not store assets in the pool and VIP wallet for a long period of time, the pool is only responsible for the safety of earnings within 3 days!' ,
            homeNotice2: '1. The Mining Pool FAQ always states "Do not store assets in the Mining Pool for long periods of time".',
            homeNotice3: '2. The VIP wallet is a non-profit facility, and it is emphasized that it can only be used for temporary use',
            homeNotice4: '3. The Mining Pool Risk Legal Statement always emphasizes the need to use the service in compliance with local laws and regulations',
            homeNotice5: '5、VIP wallet: https://wallet.always.vip',
            homeNotice: 'VIP Mining Pool is open for 0 commission mining, please enjoy it~~',
            inputEmailText:"Input Email",
            seeDetail:"See Detail",
            actionTutorial1:"Input Email，then click See Detail",
            actionTutorial2:"Click 'See Detail'",
            coin:"Coin",
            poolHash:"Pool Hash",
            netHash:"Network Hash",
            dailyIncome:"Est daily",
            stratumAddress:"Stratum Address",
            fee:"Fee",
            minPay:"Min Pay",


            emailError:'Email wrong!',
        },
        footer:{
            WalletText:"Wallet:",
            WalletName:"VIP Wallet",
            customerServiceEmailText:"Email us:",
            QQGroupText:"QQ GROUP:",
            customerServiceEmail:"grincalf@gmail.com",
            QQGroup:"824825520",
            DiscordText:"Discord:",
            Discord:"Invite Link",
            TgText:"Telegram：",
            Tg:"Link",
            copyRight:"© pool.always.vip"
        },
        tutorial:{
            mine:{
                title:"Mine Tutorial",
                step1:"1、Fill stratum address:such as stratum+tcp://grin.always.vip:3344 for grin",
                step2:"2、Take your email as username, username and rig name with '.' apart, example：Your email: grin@test.com，rig:001，so: grin@test.com.001",
                step3:"3、Set withdraw address by email, so only support email as username",
                example:"Example",
            },
            withdraw:{
                title:"Withdraw Tutorial",
                step1: "1. Register VIP wallet using mining email address",
                // step2: "2、Mining pool earnings will be automatically paid to the corresponding VIP wallet account or VIP wallet binding withdrawal address",
                step2: "2. Bind the address to receive earnings. GRIN only supports grin1 addresses, and MWC only supports MWCMQS addresses. Both support adding a memo.",
                step3: "3. The format for adding a memo is: grin1xxx:123456 or mwcmqsxxx:123456",
                // step3: "3. VIP wallet URL: https://wallet.always.vip",
                notice:"Important Notice",
                notice1: "1. Do not store assets in the mining pool and VIP wallet for a long time, the mining pool and VIP wallet are only responsible for the safety of assets within 3 days! ",
                notice2: "2. VIP Wallet is a non-profit facility and can only be used on a temporary basis",
                notice3: "3. Please use the service in compliance with local laws and regulations",
                example:"Example",
            },
            serviceMail: {
                title:"Email list",
                mail1Purpose:"Offline Reminder Email: ",
                mail1:"vip__pool@126.com ~ vip__pool6@126.com",
                mail2Purpose:"Customer Service Email: ",
                mail2:"grincalf@gmail.com",
                mail3Purpose:"VIP Wallet Email: ",
                mail3:"scuxiaoer@.126com",
            },
            law: {
                title:"Legal Risk Warning",
                content:"Before using the VIP mining pool service, please make sure that you are not restricted or prohibited from using our services by the administrative law enforcement agencies of any country or region, nor are you Cuba, Iran, North Korea, Syria or other countries subject to sanctions imposed by relevant national governments or international agencies or local residents. Please ensure that your actions comply with local laws, regulations or policies in your country or region.",
            },
            qaList:{
                title:"Questions and Answers",
            }
        },
        summary:{
            wait:"Data loading, please wait a while, do not be anxious, do not refresh frequently ~~~",
            email:"Email",
            return:"Back",
            accountInfo:"Account",
            todayIncome:"24H Income",
            toPay:"Balance",
            paid:"Paid",
            totalIncome:"Total",
            autoBill:"Request payment",
            autoBillOn:"Turned On",
            autoBillOff:"Turned Off",
            rigs:"Rigs",
            onRigs:"Online: ",
            offRigs:"Offline: ",
            allRigs:"All: ",
            rigName:"Name",
            hash15m:"1H",
            hash12h:"6H",
            hash24h:"12H",
            hashLowAlert:"Last hour's hash rate is low, please check the miner!",
            hashTable:"Speed Table",
            dailyIncome:"Payment records",
            notice:"",
            date:"Date",
            address:"Address",
            amount:"Amount",
            hashRate:"Hashrate",
            txSlateID:"TxSlateID",
            kernel:"kernel",
            status:"Status",
            statusPaid:"Paid",
            statusToPay:"Waiting",
            currentAddress:"Address：",
            setWithdrewAddress:"Set Address",
            updateWithdrewAddress:"Update Address",
            inputWithdrewAddressHint:"Pls input address:",
            inputEmailCodeHint:"Pls Email Code:",
            sendEmailCodeBtn:"Send Code",
            plsInputEmailCodeFirst:"Pls input code firstly！",
            wrongGrinAddress:"wrong address，should start with grinxx！if need MEMO，should be like grin1xxxx:123456",
            grinAddressMemoHint:"if need MEMO，should be like grin1xxxx:123456",
            wrongMWCAddress:"wrong address，should start with mwcmqsxx！if need MEMO，should be like ：mwcmqsxxxx:123456",
            mwcAddressMemoHint:"if need MEMO，should be like ：mwcmqsxxxx:123456",
            confirmUpdateAddress:"Confirm",
            plsBindAddress:"Please bind address!",
            recentPaidRecords:"No Payment Record",
            recentBlocks:"Recent Blocks",
            coin:"Coin",
            time:"Time(UTC)",
            height:"Height",
            reward:"Reward",
            noRecentReward:"No Recent Reward",
        },
        errors:{
            wrongAddress:"Wrong Address",
            wrongEmailCode:"Wrong Email Code.",
            ParamsErr               :"Param Wrong!",
            BadRequest              :"Bad Request!",
            DatabaseError           :"Registered, pls login!",
            EmailExistedError       :"Registered, pls login!",
            UserInfoError            :"Email or Password Wrong!",
            AccountNeedActivateError :"Please Activate Account Firstly",
            SendEmailError           :"Send Email Failed",
            ActivateAccountError     :"Activate Account Failed",
            ActivateAddressError     :"Activate Address Failed",
            UnexpectedError          :"Unknown Error",
            AddressInvalidError      :"Please Activate Address Firstly",
            WithdrawCloseError       :"Withdraw Close!",
            WithdrawTepCloseError    :"Click 'Sync Address' to pay the mining pool earnings directly to the wallet tor withdrawal address, such as direct payment to gate. do not store assets in VIP wallet for more than 3 days!",
            SyncOk                   : "Synchronizing addresses was successful! Subsequent pool earnings will be paid directly to your grin1 address (gate address)!" ,
            WithdrawAmountCloseError :"Withdraw Amount Wrong",
            GAuthCodeError           :"Google Auth Code Wrong",
            TokenError               :"Token Wrong",
            CoinError                :"Coin Wrong",
            GAuthBindError           :"Please bind Google Authenticator Firstly",
            EmailError               :"Email Wrong",
            BindAddressError         :"Bind Address Failed",
            BindGAuthError           :"Bind Google Authenticator Failed",
            GAuthBoundError          :"Has Bound Google Authenticator, Please Not Try Again",
            WithdrawNotFinishedError :"Please Wait Last Withdraw Request To be Finished",
            BalanceNotEnoughError    :"Balance Not Enough",
            TxNotExistsError         :"Tx not existed",
            TxNotMatchedError        :"Tx info not match",
            TryLaterError            :"Please try again later",

            AddressInvalid :"Address Wrong",
            ComingSoon :"Coming soon~",
        },

    }
}