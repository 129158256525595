<template>
  <section class="section has-text-centered" style="background-color: white;">
    <div class="container" style="padding:2rem 0 2rem 0">
      <div class="container" style=" padding:0">
        <div class="level is-mobile">
          <div class="level-left">
            <h1 class="title is-4 is-size-5-mobile">
              {{$t("msg.summary.dailyIncome")}}
            </h1>
          </div>
          <div class="level-right">
            <h1 class="title is-4 is-size-7-mobile has-text-danger">
              {{$t("msg.summary.notice")}}
            </h1>
          </div>
        </div>
      </div>
      <div class="container" style=" padding:0.2rem 0.2rem">
        <table class="table is-fullwidth is-striped is-hoverable is-sortable is-size-9-mobile" v-if="getIncomesLength(incomesA) > 0">
          <thead>
          <tr>
            <th>{{$t("msg.summary.date")}}</th>
            <th class="is-hidden-mobile">{{$t("msg.summary.address")}}</th>
            <th>{{$t("msg.summary.txSlateID")}}</th>
            <th>{{$t("msg.summary.kernel")}}</th>
            <th>{{$t("msg.summary.amount")}}</th>
            <th>{{$t("msg.summary.status")}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(income, index) in incomesA" :key='index'>
            <td>{{ income.CreatedAt.toString().split("T")[0] }}</td>
            <td class="is-hidden-mobile">{{ shortString(income.Address, 8) }}</td>
            <td @click="copySlateID(index, income.Hash)">
              <span :data-tooltip="income.Hash" v-if="income.Hash">
                  {{shortString(income.Hash,1)}}
                  &nbsp;
                  <font-awesome-icon icon='check-circle' v-if="copied===index" size="xs" />
                  <font-awesome-icon icon='copy' size="xs" v-else/>
                </span>
            </td>
            <td @click="copySlateID(index+100, income.KernelExec)">
              <span :data-tooltip="income.KernelExec" v-if="income.KernelExec">
                  {{shortString(income.KernelExec,1)}}
                  &nbsp;
                  <font-awesome-icon icon='check-circle' v-if="copied===index+100" size="xs" />
                  <font-awesome-icon icon='copy' size="xs" v-else/>
                </span>
            </td>
            <td>{{ income.Amount.toFixed(2) }}</td>
            <td>
              <p class="has-text" v-if="income.Finished">{{$t("msg.summary.paid")}}</p>
              <p class="has-text" v-else>{{$t("msg.summary.statusToPay")}}</p>
            </td>
          </tr>

          <tr class="is-hidden-mobile">
            <td>{{$t("msg.summary.currentAddress")}}</td>
            <td colspan="3">
              <p class="has-text" v-if="assets[coin].address">{{ assets[coin].address }}</p>
              <p class="has-text-danger" v-else>{{$t("msg.summary.plsBindAddress")}}</p>
            </td>
            <td colspan="2">
              <button class="button" v-if="assets[coin].address" @click="setShowAddressEdit">{{$t("msg.summary.updateWithdrewAddress")}}</button>
              <button class="button" @click="setShowAddressEdit" v-else>{{$t("msg.summary.setWithdrewAddress")}}</button>
            </td>
          </tr>

          <tr class="is-hidden-mobile" v-if="showAddressEdit">
            <td>{{$t("msg.summary.inputEmailCodeHint")}}</td>
            <td colspan="3">
              <div class="field">
                <div class="control">
                  <input class="input"  type="email" v-model=emailCode>
                </div>
              </div>
            </td>
            <td colspan="3">
<!--              <button class="button"  :disabled="hasSentEmailCode" @click="handleClick">{{$t("msg.summary.sendEmailCodeBtn")}}</button>-->
              <button class="button"  :disabled="hasSentEmailCode" @click="handleClick">{{ sendBtnText }}</button>
            </td>
          </tr>
          <tr class="is-hidden-mobile" v-if="showAddressEdit">
            <td>{{$t("msg.summary.inputWithdrewAddressHint")}}</td>
            <td colspan="3">
              <div class="field">
                <div class="control">
                  <input class="input" @keyup="checkAddress" type="email" v-model=addressToBind>
                </div>
                <p class="help has-text-danger">{{ note }}</p>
              </div>
            </td>
            <td colspan="2">
              <button class="button"  @click="setAddress">{{$t("msg.summary.confirmUpdateAddress")}}</button>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="columns is-mobile is-centered" v-show="getIncomesLength(incomesA) === 0">
          <table class="table is-fullwidth is-striped is-hoverable is-sortable is-size-9-mobile" >
            <tbody>
            <tr>
              <td colspan="6">{{$t("msg.summary.recentPaidRecords")}}</td>
            </tr>
            <tr class="is-hidden-mobile">
              <td>{{$t("msg.summary.currentAddress")}}</td>
              <td colspan="3">
                <p class="has-text" v-if="assets[coin].address">{{ assets[coin].address }}</p>
                <p class="has-text-danger" v-else>{{$t("msg.summary.plsBindAddress")}}</p>
              </td>
              <td colspan="2">
                <button class="button" v-if="assets[coin].address" @click="setShowAddressEdit">{{$t("msg.summary.updateWithdrewAddress")}}</button>
                <button class="button" @click="setShowAddressEdit" v-else>{{$t("msg.summary.setWithdrewAddress")}}</button>
              </td>
            </tr>
            <tr class="is-hidden-mobile" v-if="showAddressEdit">
              <td>{{$t("msg.summary.inputEmailCodeHint")}}</td>
              <td colspan="3">
                <div class="field">
                  <div class="control">
                    <input class="input"  type="email" v-model=emailCode>
                  </div>
                </div>
              </td>
              <td colspan="3">
                <!--              <button class="button"  :disabled="hasSentEmailCode" @click="handleClick">{{$t("msg.summary.sendEmailCodeBtn")}}</button>-->
                <button class="button"  :disabled="hasSentEmailCode" @click="handleClick">{{ sendBtnText }}</button>
              </td>
            </tr>
            <tr class="is-hidden-mobile" v-if="showAddressEdit">
              <td>{{$t("msg.summary.inputWithdrewAddressHint")}}</td>
              <td colspan="3">
                <div class="field">
                  <div class="control">
                    <input class="input" @keyup="checkAddress" type="email" v-model=addressToBind>
                  </div>
                  <p class="help has-text-danger">{{ note }}</p>
                </div>
              </td>
              <td colspan="2">
                <button class="button"  @click="setAddress">{{$t("msg.summary.confirmUpdateAddress")}}</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div class="field is-horizontal">
          </div>
        </div>
      </div>
      <div style="background-color:white">
      </div>
    </div>
  </section>
</template>

<script>
import {messageBus} from "@/libs/messagebus";
import {sendEmailCode, setAddress} from "@/libs/api";

export default {
  name: 'Payments',
  comments:{
  },
  computed:{
    isDisabled() {
      // you can  check your form is filled or not here.
      return this.hasSentEmailCode;
    }
  },
  data() {
    return {
      email: "",
      emailCode:"",
      sendBtnText:this.$t("msg.summary.sendEmailCodeBtn"),
      hasSentEmailCode:false,
      assets: null,
      incomesA: null,
      coin: null,
      sendCodeGap:180,
      copied:'',
      showAddressEdit:false,
      note:"",
      addressToBind:"",
    }
  },

  async created() {
    this.email = this.$route.path.split('/')[2]
    this.coin = localStorage.getItem('coin');
    if (!this.coin) {
      this.coin = "GRIN"
    }
    messageBus.$on('in', (data) => {
      // setTimeout(() => {
        this.assets = data[0];
        this.incomesA = data[1];
        // }, 1000);

    });

  },

  methods: {
    copySlateID(index, tx_slate_id){
      this.$copyText(tx_slate_id).then(()=>{
        this.copied = index
      })
    },
    shortString(longStr, showLen){
      let maxLen = longStr.toString().length
      return longStr.toString().slice(0, showLen) + "*" + longStr.toString().slice(-showLen, maxLen)
    },
    getIncomesLength(item){
      if (item === null) {
        return 0
      }
      return item.length
    },
    setShowAddressEdit() {
      this.showAddressEdit = true
    },
    async setAddress(){
      try {
        if (!this.checkAddress()) {
          alert(this.$t('msg.errors.wrongAddress'))
          return
        }

        let resp = await setAddress(this.email, this.coin, this.addressToBind, this.emailCode)
        if (resp.data.msg === "ok") {
          alert(resp.data.msg);
          this.showAddressEdit = false;
          this.assets[this.coin].address = this.addressToBind;
        }else {
          alert(resp.data.msg)
        }
      } catch (error) {
        if (error.response) {
          let msg = this.handleError(error.response.data.message)
          alert(msg)
        }
      }
    },
    handleClick() {
      if (!this.hasSentEmailCode){
          this.sendEmailCode();
          this.hasSentEmailCode = true;
          this.sendBtnText = this.sendCodeGap;
          for (let i = 1;i!==this.sendCodeGap+1;i++){
            setTimeout(() => {
              this.sendBtnText = this.sendBtnText - 1;
              if (i===this.sendCodeGap){
                this.hasSentEmailCode = false;
                this.sendBtnText = this.$t("msg.summary.sendEmailCodeBtn");
              }
            }, 1000*i);
        }
      }
    },
    async sendEmailCode(){
      try {

        let resp = await sendEmailCode(this.email, this.coin)
        if (resp.data.msg) {
          alert(resp.data.msg);
        }
      } catch (error) {
        if (error.response) {
          let msg = this.handleError(error.response.data.message)
          alert(msg)
        }
      }
    },
    checkAddress() {
      if (this.emailCode.length !== 7) {
        this.note = this.$t("msg.summary.plsInputEmailCodeFirst");
        return false
      }
      switch (this.coin) {
        case "GRIN":
          if (!this.addressToBind.toLowerCase().startsWith("grin")){
            this.note = this.$t("msg.summary.wrongGrinAddress")
            return false
          }else {
            this.note = ""
            if (this.addressToBind.length > "grin1n26np6apy07576qx6yz4qayuwxcpjvl87a2mjv3jpk6mnyz8y4vq65ahjm".length){
              this.note = this.$t("msg.summary.grinAddressMemoHint")
            }
            return true
          }
        case "MWC":
          if (!this.addressToBind.toLowerCase().startsWith("mwcmqs")){
            this.note = this.$t("msg.summary.wrongMWCAddress")
            return false
          }else {
            this.note = ""
            if (this.addressToBind.length > "mwcmqs://q5YY9s8czneMQ7UpvhW9BXfzLFKwDkTUaxpJMqzXJm5zLoiAvBNH".length){
              this.note = this.$t("msg.summary.mwcAddressMemoHint")
            }
            return true
          }
      }
    },
    handleError(err) {
      let msg = ''
      switch (err) {
        case 'param_error':
          msg = this.$t('msg.errors.ParamsErr')
          break
        case 'bad_request':
          msg = this.$t('msg.errors.BadRequest')
          break
        case 'database_error':
          msg = this.$t('msg.errors.DatabaseError')
          break
        case 'unexpected_error':
          msg = this.$t('msg.errors.UnexpectedError')
          break
        case 'address_need_activate':
          msg = this.$t('msg.errors.AddressInvalidError')
          break
        case 'token_wrong':
          msg = this.$t('msg.errors.TokenError')
          break
        case 'coin_wrong':
          msg = this.$t('msg.errors.CoinError')
          break
        case 'bind_address_error':
          msg = this.$t('msg.errors.BindAddressError')
          break
        case '身份验证失败':
          msg = this.$t('msg.errors.ComingSoon')
          break
        case '请求太频繁！| Too many request':
          msg = '请求太频繁！| Too many request'
          break
        default:
          msg = this.$t('msg.errors.UnexpectedError')
          break
      }
      return msg
    },
  },

  watch:{
    coin:async function (newVal){
      localStorage.setItem('coin',newVal);
    },
    incomesA:async function (newVal){
      console.log("wathch-"+this.getIncomesLength(newVal))
    }
  }
}
</script>