<template>
  <section class="section" style="padding-top:0;">
    <div class="container" style="padding: 1rem 2rem;">
      <footer class="footer" style="background-color:white;">
        <div class="content has-text-centered">
          <p>
            <span class="logo" style="font-size:1rem">{{$t("msg.footer.customerServiceEmailText")}}</span><a class="tag">{{$t("msg.footer.customerServiceEmail")}}</a>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
<!--            <span class="logo" style="font-size:1rem">{{$t("msg.footer.QQGroupText")}}</span><a class="tag">{{$t("msg.footer.QQGroup")}}</a>-->
<!--            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>-->
<!--            <span class="logo" style="font-size:1rem">{{$t("msg.footer.DiscordText")}}</span><a class="tag" href="https://discord.gg/YueHHxyjZM">{{$t("msg.footer.Discord")}}</a>-->
<!--            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>-->
<!--            <span class="logo" style="font-size:1rem">{{$t("msg.footer.TgText")}}</span><a class="tag" href="https://t.me/poolalwaysvip">{{$t("msg.footer.Tg")}}</a>-->
<!--            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>-->
            <span class="logo" style="font-size:1rem">{{$t("msg.footer.WalletText")}}</span><a class="tag" href="https://wallet.always.vip">{{$t("msg.footer.WalletName")}}</a>
          </p>
          <p>{{$t("msg.footer.copyRight")}}</p>
        </div>
      </footer>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped>
</style>
