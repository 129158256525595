<template>
  <section class="section has-text-centered" style="padding-top:5rem;">

    <div class="container" style="background-color: white; padding: 2rem 2rem 6rem 2rem;">
      <div class="level-center">
        <nav class="navbar" role="navigation" aria-label="main navigation">
          <div class="navbar-brand">
            <a class="navbar-item" href="http://pool.always.vip">
              <h1 class="logo"><span style="color: #ff1b10">{{$t("msg.search.poolName")}}</span></h1>
            </a>
          </div>
          <div class="navbar-menu">
            <div class="navbar-end">
              <div class="navbar-item">
                <div class="select is-small">
                  <select v-model="lang">
                    <option  selected>Language</option>
                    <option value="zh">简体中文</option>
                    <option value="en">English</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>

    <div  style="background-color: white; padding: 2rem 2rem 6rem 2rem;">
      <div style="display: inline;text-align: center;">
        <h4 class="is-7 is-size-7-mobile">
         <big class="has-text-danger-dark is-size-12">  {{$t("msg.search.homeNotice1")}}</big>
<!--             <big class="has-text-success-dark">{{$t("msg.search.homeNotice")}}</big>-->
<!--         <p class="has-text-success-dark">{{$t("msg.search.homeNotice1")}}</p>-->
<!--         <p class="has-text-danger-dark">{{$t("msg.search.homeNotice1")}}</p>-->
<!--         <p class="has-text-success-dark">{{$t("msg.search.homeNotice2")}}</p>-->
<!--         <p class="has-text-success-dark">{{$t("msg.search.homeNotice3")}}</p>-->
<!--         <p class="has-text-success-dark">{{$t("msg.search.homeNotice4")}}</p>-->
<!--         <p class="has-text-success-dark">{{$t("msg.search.homeNotice5")}}</p>-->
        </h4>
      </div>
    </div>

    <div class="container" style="background-color: white;padding: 0 2rem 3rem 0;">
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label ">{{$t("msg.search.inputEmailText")}}</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input class="input" @keyup="checkEmail" type="email" v-model=email>
            </div>
            <p class="help has-text-danger">{{ note }}</p>
          </div>
          <div class="field">
            <div class="control">
              <button class="button" style="color: black;background-color: white" @click="search">
                {{$t("msg.search.seeDetail")}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

<!--    <p style="color:red;padding: 0 0 0 0;">{{$t("msg.search.homeNotice")}}</p>-->


    <div class="container" style="background-color: white;padding: 0 2rem 5rem;">
      <div class="field is-horizontal">
        <div class="field-body">
          <table class="table is-fullwidth is-striped is-hoverable is-sortable is-size-7-mobile">
            <thead>
            <tr>
              <th> {{$t("msg.search.coin")}}</th>
              <th class="is-hidden-mobile"> {{$t("msg.search.stratumAddress")}}</th>
<!--              <th> {{$t("msg.search.poolHash")}}</th>-->
<!--              <th> {{$t("msg.search.netHash")}}</th>-->
<!--              <th> {{$t("msg.search.dailyIncome")}}</th>-->
              <th class="is-hidden-mobile"> {{$t("msg.search.fee")}}</th>
              <th> {{$t("msg.search.minPay")}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(s, index) in pools" :key='index' >
              <td style="display: table-cell; vertical-align: middle">{{ s.Name }}</td>
              <td class="is-hidden-mobile" style="display: table-cell; vertical-align: middle">
                <p v-for="(ss, ssindex) in s.StratumAddress" :key="ssindex">{{ss}}</p>
              </td>
<!--              <td style="display: table-cell; vertical-align: middle">{{ s.PoolHash }}</td>-->
<!--              <td style="display: table-cell; vertical-align: middle">{{ s.NetworkHash }}</td>-->
<!--              <td style="display: table-cell; vertical-align: middle">{{ s.DailyIncome }}</td>-->
              <td style="display: table-cell; vertical-align: middle" class="is-hidden-mobile">{{ s.Fee }}</td>
              <td style="display: table-cell; vertical-align: middle">{{ s.MinPay }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>


  </section>
</template>

<script>
import {getPoolsInfo} from '@/libs/api.js'

export default {
  name: 'Search',
  data() {
    return {
      email: '',
      note: this.$t("msg.search.actionTutorial1"),
      pools: [],
      lang: this.$i18n.locale,
    }
  },
  async created() {
    let resp = await getPoolsInfo()
    if (resp.data && resp.data.length > 0) {
      this.pools = resp.data
    }
  },
  methods: {
    checkEmail() {
      if (!this.email.includes("@")) {
        this.note = this.$t("msg.search.emailError")
        return false
      } else {
        this.note = this.$t("msg.search.actionTutorial2")
        return true
      }
    },
    search() {
      if (this.checkEmail()) {
        localStorage.setItem('email', this.email);
        this.$router.push('/detail/' + this.email)
      }
    }
  },
  watch: {
    lang:function(newVal){
      this.$i18n.locale = newVal
    }
  }
}
</script>
