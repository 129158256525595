import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from "@/views/Home";
import Detail from "@/views/Detail";

Vue.use(VueRouter)

const routes = [
  // { path: '/', name: 'home', component: Home, meta: { requiresAuth: false}},
  { path: '/detail/:userName/', name: 'summary', component: Detail, meta: { requiresAuth: false}},
  { path: '/*', name: 'home', component: Home, meta: { requiresAuth: false}},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.logined){
      next()
      return
    }
    next('/') 
  }else{
    next() 
  }
})

export default router
