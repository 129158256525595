<template>
  <div style="background-color: white;">
    <Search style="margin-top:-1rem"/>
    <Tutorial style="margin-top:-1rem"/>
    <Footer style="margin-top:-3rem"/>
  </div>
</template>

<script>
import Search from '@/components/Search.vue'
import Footer from "@/components/footer";
import Tutorial from "@/components/tutorial";

export default {
  name: 'Home',
  components: {
    Search,
    Tutorial,
    Footer,
  }
}
</script>


