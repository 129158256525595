<template>
  <section class="section has-text-centered" style="background-color: white;">
    <div class="container" style="padding:0">
      <div class="container" style=" padding:0">
        <div class="level is-mobile">
          <div class="level-left">
            <h1 class="title is-4 is-size-5-mobile">
              {{$t("msg.summary.recentBlocks")}}
            </h1>
          </div>
        </div>
      </div>
      <hr style="margin:0.15rem 0"/>
      <div class="container" style=" padding:0.2rem 0.2rem">
        <table class="table is-fullwidth is-striped is-hoverable is-sortable is-size-7-mobile" v-if="getRewardsLength(rewards) > 0">
          <thead>
          <tr>
            <th>{{$t("msg.summary.coin")}}</th>
            <th>{{$t("msg.summary.time")}}</th>
            <th>{{$t("msg.summary.height")}}</th>
            <th>{{$t("msg.summary.reward")}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(reward, index) in rewards" :key='index'>
            <td>{{ coin }}</td>
            <td>{{ reward.Time.toString().slice(5,16).replace("T"," ") }}</td>
            <td>{{ reward.Height }}</td>
            <td>{{ reward.Value.toFixed(4) }}</td>
          </tr>
          </tbody>
        </table>
        <div class="columns is-mobile is-centered" v-show="getRewardsLength(rewards) === 0">
          <div class="column is-narrow">
            <div class="notification is-warning has-text" style="margin:75px">
              <h4 class="title is-size-4 is-size-6-mobile">{{$t("msg.summary.noRecentReward")}}</h4>
            </div>
          </div>
        </div>
        <br/>
      </div>

    </div>
  </section>
</template>

<script>
import {messageBus} from "@/libs/messagebus";

export default {
  name: 'Blocks',
  comments:{
  },
  data() {
    return {
      coin: "",
      rewards: null,
    }
  },

  async created() {
    this.coin = localStorage.getItem('coin');
    if (!this.coin) {
      this.coin = "GRIN"
    }
    messageBus.$on('re', (data) => {
      // alert("C - "+data);
      this.rewards = data;
    });
  },

  methods: {
    getRewardsLength(item){
      if (item === null) {
        return 0
      }
      return item.length
    },
  },

  watch:{
    coin:async function (newVal){
      localStorage.setItem('coin',newVal);
      // await this.refresh()
    }
  }
}
</script>