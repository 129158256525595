<template>
  <div style="background-color: white;">
    <Summary style="margin-top:-1rem"/>
    <Payments style="margin-top:-7rem"/>
    <Blocks style="margin-top:-7rem"/>
    <Footer style="margin-top:-5rem"/>
  </div>
</template>

<script>
import Footer from "@/components/footer";
import Summary from "@/components/Summary";
import Blocks from "@/components/Blocks";
import Payments from "@/components/Payments";

export default {
  name: 'Detail',
  components: {
    Summary,
    Payments,
    Blocks,
    Footer
  }
}
</script>

